<script>
import { ValidationObserver } from 'vee-validate'
import { computed, ref } from 'vue'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseMetafield from '/~/components/base/metafield/base-metafield.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useForm } from '/~/composables/base/use-form'
import { usePayeeBankAccountForm, usePayees } from '/~/composables/payees'

export default {
  name: 'edit-payee-modal-v2',
  components: {
    BaseInput,
    BaseButton,
    BaseIcon,
    BaseMdl,
    BaseMetafield,
    ValidationObserver,
  },
  props: {
    payee: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { updatePayee } = usePayees()
    const { bankAccountSchemaEditName, form } = usePayeeBankAccountForm()
    const { validationObserverRef } = useForm()

    const processing = ref(false)
    const backendErrors = ref({})

    form.value = { ...props.payee }
    form.value.name = props.payee.name || props.payee.accountName
    form.value.bankAccountGroup = {
      bsb: props.payee.bsb,
      accountNumber: props.payee.accountNumber,
    }

    const isSymbionPayee = computed(() => props.payee.type === 'symbion')
    const isBpayPayee = computed(() => props.payee.type === 'bpay')
    const isBankAccountPayee = computed(
      () => props.payee.type === 'bankAccount'
    )

    function onCancel() {
      emit('hide')
      modal.hide()
    }

    async function onUpdate() {
      processing.value = true

      try {
        const response = await updatePayee(props.payee.id, {
          name: form.value.name,
        })

        if (response) {
          emit('hide')
          modal.hide()
        }
      } catch (error) {
        console.error('bill-payments', error)
      } finally {
        processing.value = false
      }
    }

    return {
      isSymbionPayee,
      isBpayPayee,
      isBankAccountPayee,
      form,
      processing,
      backendErrors,
      validationObserverRef,
      onCancel,
      onUpdate,
      bankAccountSchemaEditName,
    }
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ invalid, handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <base-mdl
      fullscreen="mobile"
      width="sme"
      border-radius="2xl"
      layout="v2"
      :title="isSymbionPayee ? 'Edit account name' : 'Edit payee name'"
    >
      <template #icon>
        <div
          class="mx-auto flex h-16 w-16 items-center justify-center rounded-lg bg-primary"
        >
          <span class="text-white">
            <base-icon svg="heroicons/solid/pencil-square" :size="46" />
          </span>
        </div>
      </template>
      <div class="flex flex-col items-center">
        <div v-if="isSymbionPayee" class="mb-8 text-eonx-neutral-600">
          Set a custom account name to easily differentiate between your
          statements. This is set to account number by default.
        </div>

        <div class="w-full">
          <template v-if="isSymbionPayee">
            <div class="mb-8">
              <base-input
                v-model="form.name"
                :validation="{
                  rules: 'required|max:256|min:2',
                  name: 'Account name',
                }"
                :error="backendErrors.name"
                :disabled="processing"
                label="Account name"
                required
                name="name"
                maxlength="256"
              />
            </div>
          </template>

          <template v-else-if="isBpayPayee">
            <div class="mb-8 space-y-6">
              <base-input
                v-model="form.name"
                :validation="{
                  rules: 'required|max:256|min:2',
                  name: 'Biller name',
                }"
                :error="backendErrors.name"
                :disabled="processing"
                label="Biller name"
                required
                name="name"
                maxlength="256"
              />

              <base-input
                :value="payee.reference"
                label="Reference no."
                disabled
                required
              />

              <base-input
                :value="payee.billerCode"
                label="Biller code"
                disabled
                required
              />
            </div>
          </template>

          <template v-else-if="isBankAccountPayee">
            <base-metafield
              v-model="form"
              :submitting="processing"
              :schema="bankAccountSchemaEditName"
              :backend-errors="backendErrors"
            />
          </template>
        </div>

        <div class="mt-8 flex w-full space-x-2.5">
          <base-button
            class="flex-auto"
            look="outlined-color"
            @click="onCancel"
          >
            Cancel
          </base-button>
          <base-button
            class="flex-auto"
            :disabled="processing || !form.name || invalid"
            :loading="processing"
            @click="handleSubmit(onUpdate)"
          >
            Save
          </base-button>
        </div>
      </div>
    </base-mdl>
  </validation-observer>
</template>
